import React from 'react'
import styled from '@emotion/styled'

// Components
import { Loader } from '../../../components/Loader'
import ProjectsNav from '../../../components/ProjectsNav'
import { H2 } from '../../../components/Titles'
import { Paragraph, Quote } from '../../../components/Texts'
import Video from '../../../components/Video'
import Image from '../../../components/Image'
import Header from '../../../components/Header'
import { MainGrid, Grid, GridContent } from '../../../components/Grids'

// Hooks
import useData from '../../../hooks/useData'
import useDeviceDetect from '../../../hooks/useDeviceDetect'

// Constants
import { outerMargin } from '../../../constants'

// Assets
import commuteVideo002 from './assets/commute-video-002.mp4'
import commuteVideo003 from './assets/commute-video-003.mp4'
import commuteImg001 from './assets/commute-img-001.jpg'
// import commuteImg002 from './assets/commute-img-002.jpg'
import commuteImg003 from './assets/commute-img-003.jpg'
import commuteImg004 from './assets/commute-img-004.jpg'
import commuteImg005 from './assets/commute-img-005.jpg'
import commuteImg006 from './assets/commute-img-006.jpg'

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 4rem 2rem 2rem 2rem;
  }
`

const TitleContainer = styled(H2)`
  margin: 0 0 8rem 0;

  @media (max-width: 640px) {
    margin: 2rem 0 4rem 0;
  }
`

const ImageContainer = styled(GridContent)`
  margin: 8rem 0 10rem 0;

  @media (max-width: 640px) {
    margin: 2rem 0 4rem 0;
  }
`

const VideoContainer = styled(GridContent)`
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 0 0;
  }
`

const Commute = () => {
  const [data, isLoaded] = useData('/project-commute')
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <>
        <Header page='project-commute' backLink />

        <PageContainer pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>
          <Grid columns='repeat(12, 1fr)'>

            <GridContent size='7 / -1'>
              <TitleContainer dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.title01', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph01', 'content') }} />
            </GridContent>

            <VideoContainer size='1 / -1'>
              <Video src={commuteVideo003} height={800} autoPlay />
            </VideoContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph02', 'content') }} />
            </GridContent>

            <VideoContainer size='span 12'>
              <Video src={commuteVideo002} height={800} autoPlay />
            </VideoContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph03', 'content') }} />
            </GridContent>

            <GridContent size='3 / -4'>
              <Quote dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.quote01', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1' style={{ marginBottom: '4rem' }}>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph04', 'content') }} />
            </GridContent>

            <ImageContainer size='span 6'>
              <Image src={commuteImg005} alt='comm1' height={420} type='contain' />
            </ImageContainer>

            <ImageContainer size='span 6'>
              <Image src={commuteImg006} alt='comm1' height={420} type='contain' />
            </ImageContainer>

            <GridContent size='7 / -1' style={{ marginBottom: '4rem' }}>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph05', 'content') }} />
            </GridContent>

            <ImageContainer size='span 12'>
              <Image src={commuteImg001} alt='comm1' height={isMobile ? 400 : 800} />
            </ImageContainer>

            <GridContent size='7 / -1' style={{ marginBottom: '4rem' }}>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph06', 'content') }} />
            </GridContent>

            <ImageContainer size='span 6'>
              <Image src={commuteImg003} alt='comm1' height={420} type='contain' />
            </ImageContainer>

            <ImageContainer size='span 6'>
              <Image src={commuteImg004} alt='comm1' height={420} type='contain' />
            </ImageContainer>

          </Grid>

          <ProjectsNav project='commute' />
        </PageContainer>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Commute
