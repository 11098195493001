import React, { useRef, useState, useEffect, useMemo } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame } from 'react-three-fiber'
// import { EffectComposer, DepthOfField, Noise, Vignette } from 'react-postprocessing'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import styled from '@emotion/styled'
import { useTransition, animated } from 'react-spring'
import { H1 } from '../../components/Titles'

// Hooks
// import useDeviceDetect from '../../hooks/useDeviceDetect'

// const CameraController = () => {
//   const { camera, gl } = useThree()

//   useEffect(
//     () => {
//       const controls = new OrbitControls(camera, gl.domElement)

//       controls.minDistance = 3
//       controls.maxDistance = 500

//       return () => {
//         controls.dispose()
//       }
//     },
//     [camera, gl]
//   )
//   return null
// }

const CoverContainer = styled.div`
  position: relative;
  height: calc(100vh - 65rem);
  z-index: 1;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;

  @media (max-width: 640px) {
    height: 200px;
  }
`

const H2Container = styled(H1)`
  line-height: 1;
  transition: all 1s ease-in-out;
  width: 50vw;

  @media (max-width: 640px) {
    font-size: 4.2rem;
    width: 80vw;
  }
`

const CanvasContainer = styled(Canvas)`
  width: 100vw;
  height: ${window.innerHeight}px;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 0;
`

const usePointColors = ({ data, selectedPoint, selectedColor, defaultColor }) => {
  const scratchColor = new THREE.Color()
  const numPoints = data.length
  const colorAttrib = useRef()
  const colorArray = useMemo(() => new Float32Array(numPoints * 3), [
    numPoints
  ])

  useEffect(() => {
    for (let i = 0; i < data.length; ++i) {
      scratchColor.set(
        data[i] === selectedPoint.data ? selectedColor : defaultColor
      )
      scratchColor.toArray(colorArray, i * 3)
    }
    colorAttrib.current.needsUpdate = true
  }, [data, selectedPoint, colorArray, selectedColor, defaultColor, scratchColor])

  return { colorAttrib, colorArray }
}

const useMousePointInteraction = ({ data, selectedPoint, onSelectPoint }) => {
  // track mousedown position to skip click handlers on drags
  const mouseDownRef = useRef([0, 0])
  const handlePointerDown = e => {
    mouseDownRef.current[0] = e.clientX
    mouseDownRef.current[1] = e.clientY
  }

  const handleClick = event => {
    const { instanceId, clientX, clientY } = event
    const downDistance = Math.sqrt(
      Math.pow(mouseDownRef.current[0] - clientX, 2) + Math.pow(mouseDownRef.current[1] - clientY, 2)
    )

    // skip click if we dragged more than 5px distance
    if (downDistance > 5) {
      event.stopPropagation()
      return
    }

    // index is instanceId if we never change sort order
    const index = instanceId
    const point = { data: data[index], msg: 'test' }
    // console.log(point, index)
    // console.log('Selected point: ', point)
    // toggle the point
    if (point.data === selectedPoint) {
      onSelectPoint(null)
    } else {
      onSelectPoint(point)
    }
  }

  return { handlePointerDown, handleClick }
}

const updateInstancedMeshMatrices = ({ mesh, data }) => {
  if (!mesh) return
  const scratchObject3D = new THREE.Object3D()

  // set the transform matrix for each instance
  for (let i = 0; i < data.length; ++i) {
    const size = data[i].size
    const x = data[i].x
    const y = data[i].y
    const z = data[i].z

    scratchObject3D.position.set(x, y, z)
    scratchObject3D.scale.set(size, size, size)
    scratchObject3D.updateMatrix()
    mesh.setMatrixAt(i, scratchObject3D.matrix)
  }

  mesh.instanceMatrix.needsUpdate = true
}

const animatePoints = ({ mesh, data }) => {
  mesh.rotation.x = mesh.rotation.y += 0.001

  for (let i = 0; i < data.length; ++i) {
    data[i].x = data[i].x -= 0.001 * Math.cos(i)
    data[i].y = data[i].y -= 0.001 * Math.sin(i)
    data[i].z = data[i].z -= 0.001 * Math.tan(i)
  }
  updateInstancedMeshMatrices({ mesh, data })
}

const InstancedPoints = ({ data, selectedPoint, onSelectPoint, selectedColor, defaultColor, props }) => {
  const meshRef = useRef()
  const numPoints = data.length

  useEffect(() => {
    updateInstancedMeshMatrices({ mesh: meshRef.current, data })
  }, [data])

  useFrame(() => animatePoints({ mesh: meshRef.current, data }))

  const { handleClick, handlePointerDown } = useMousePointInteraction({ data, selectedPoint, onSelectPoint })
  const { colorAttrib, colorArray } = usePointColors({ data, selectedPoint, selectedColor, defaultColor })

  return (
    <instancedMesh
      ref={meshRef}
      args={[null, null, numPoints]}
      frustumCulled={false}
      onClick={handleClick}
      onPointerDown={handlePointerDown}
    >
      <sphereBufferGeometry attach='geometry' args={[1, 16, 16]}>
        <instancedBufferAttribute
          ref={colorAttrib}
          attachObject={['attributes', 'color']}
          args={[colorArray, 3]}
        />
      </sphereBufferGeometry>
      <meshStandardMaterial
        attach='material'
        vertexColors={THREE.VertexColors}
      />
    </instancedMesh>
  )
}

const BaselineSwitch = () => {
  const [items] = useState([
    { title: 'I design human–computer interactions', id: 0 },
    { title: 'I give shape <br />to data', id: 1 },
    { title: 'I create immersive <br />& aesthetic experiences', id: 2 },
    { title: 'I solve complex problems with algorithms', id: 3 },
    { title: 'I study users\' behaviors to better address their needs.', id: 4 }
  ])
  const [index, setIndex] = useState(0)

  const fadingTextPropsTransition = useTransition(items[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 600, friction: 220, delay: 12000 }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => (state + 1) % 5)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {
        fadingTextPropsTransition.map(({ item, props, key }) => (
          <animated.div key={key} style={{ ...props, position: 'absolute' }}>
            <H2Container dangerouslySetInnerHTML={{ __html: item.title }} />
          </animated.div>
        ))
      }
    </>
  )
}

const HeaderVisual = () => {
  // const { isMobile } = useDeviceDetect()
  const data = new Array(20000).fill(0).map((d, id) => ({
    id: id,
    x: id * Math.cos(id) * 0.1,
    y: id * Math.sin(id) * 0.1,
    z: id * Math.tan(id) * 0.1,
    size: id * Math.random() * 0.1 / 150
  }))
  const [selectedPoint, setSelectedPoint] = useState({ data: { id: null }, msg: null })
  const selectedColor = '#FF0000'
  const defaultColor = '#FFFFFF'

  const render = () => {
    return (
      <>
        <CoverContainer>
          <BaselineSwitch />
        </CoverContainer>

        <CanvasContainer resize={{ scroll: false }} camera={{ position: [0, 0, 800], rotation: [0, Math.PI / 3, 0], far: 15000, fov: 40 }}>
          <ambientLight intensity='0.4' />

          <InstancedPoints
            data={data}
            selectedColor={selectedColor}
            defaultColor={defaultColor}
            selectedPoint={selectedPoint}
            onSelectPoint={setSelectedPoint}
          />

          {/* <EffectComposer>
            <DepthOfField focusDistance={1.2} focalLength={0.1} bokehScale={5} height={1100} />
            <Noise opacity={0.02} />
            <Vignette eskil={false} offset={0.4} darkness={1.1} />
          </EffectComposer> */}

        </CanvasContainer>
      </>
    )
  }

  return (
    render()
  )
}
export default HeaderVisual
