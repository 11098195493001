import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

// Hooks
import useData from '../../hooks/useData'
import useModal from '../../hooks/useModal'

// Components
import { Loader } from '../Loader'
import { Grid, MainGrid } from '../Grids'
import { H5, H2 } from '../Titles'
import { SmallParagraph } from '../Texts'
import Colophon from '../Colophon'

// Constants
import { darkGreen, outerMargin } from '../../constants'

const FooterLinks = styled.p`
  cursor: pointer;
  font-family: 'Soehne';
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 150%;
  &:hover {
    text-decoration: underline;
  }
  a {
    color: ${darkGreen};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const MentionsContainer = styled(Grid)`
  @media (max-width: 640px) {
    padding: 2rem;
    grid-template-columns: 4fr 1fr;
    p {
      line-height: 1.4;
    }
  }
`

const ContactContainer = styled(Grid)`
  @media (max-width: 640px) {
    margin-bottom: 4rem;
  }
`

const FooterContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 6rem 2rem 2rem 2rem;
    margin-top: 2rem;

    ul {
      margin-top: 2rem;
      padding: 2rem 0;
    }
  }
`

const CopyToClipboard = () => {
  const [copySuccess, setCopySuccess] = useState()
  const elToCopy = useRef(null)

  const copyElement = () => {
    const elem = document.createElement('textarea')
    document.body.appendChild(elem)
    elem.value = 'ludovic.riffault@gmail.com'
    elem.select()
    document.execCommand('copy')
    document.body.removeChild(elem)

    setCopySuccess('Copied!')
    setTimeout(() => setCopySuccess(null), 1000)
  }

  return (
    <>
      {
        document.queryCommandSupported('copy') &&
          <span>{copySuccess}</span>
      }
      <span onClick={copyElement} ref={elToCopy}>
        {
          copySuccess ? true : 'Copy mail to clipboard'
        }
      </span>
    </>
  )
}

const Footer = () => {
  const [data, isLoaded] = useData('/nav')
  const { isShowing, toggle } = useModal()

  const linksList = data.filter(d => d.type === 'internal').map((d, i) => {
    return (
      <li key={i}>
        <FooterLinks><Link to={d.route}>{d.title}</Link></FooterLinks>
      </li>
    )
  })

  const externalLinks = data.filter(d => d.type === 'external').map((d, i) => {
    return (
      <li key={i}>
        <FooterLinks><a href={d.route} target='_blank' rel='noopener noreferrer'>{d.title}</a></FooterLinks>
      </li>
    )
  })

  const render = () => {
    return (
      <>
        <FooterContainer pads={`15vh calc(${outerMargin} + 4rem) 10vh`}>
          <Grid columns='repeat(2, 1fr)'>
            <ContactContainer>
              <H5>Contact</H5>
              <H2 style={{ fontSize: '3.4rem', margin: '0 0 4rem 0' }}>Get in touch!</H2>
              <FooterLinks><CopyToClipboard /></FooterLinks>
              <FooterLinks><a href='mailto:ludovic.riffault@gmail.com'>or use your default mail client</a></FooterLinks>
            </ContactContainer>
            <Grid columns='repeat(2, 1fr)'>
              <ul>
                <H5>Sitemap</H5>
                {linksList}
              </ul>
              <ul>
                <H5>External links</H5>
                {externalLinks}
              </ul>
            </Grid>
          </Grid>
        </FooterContainer>

        <MainGrid pads='4rem'>
          <MentionsContainer columns='repeat(2, 1fr)'>
            <SmallParagraph>Copyright Ludovic Riffault – {new Date().getFullYear()} All rights reserved.</SmallParagraph>
            <SmallParagraph onClick={toggle} style={{ justifySelf: 'right', cursor: 'pointer' }}>Colophon</SmallParagraph>
            <Colophon isShowing={isShowing} hide={toggle} />
          </MentionsContainer>
        </MainGrid>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Footer
