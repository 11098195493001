import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// Components
import { Loader } from '../Loader'
import { SmallParagraph } from '../Texts'
import { H3, H5 } from '../Titles'

// Constants
import { darkGreen, beige } from '../../constants'

// Assets
import icoRightArrow from '../../assets/icons/ico.right-arrow.svg'

// Hooks
import useData from '../../hooks/useData'

const LinkContainer = styled.a`
  font-family: 'Soehne';
  font-size: 1.8rem;
  font-weight: 400;
  text-decoration: none;
  color: white;
  text-align: left;
  display: block;
  white-space: nowrap;
  margin-top: 2rem;

  &:after {
    content: " ";
    display: inline-block;
    width: 20px;
    height: 12px;
    margin-left: 2rem;
    background-color: ${beige};
    -webkit-mask-image: url(${icoRightArrow});
    mask-image: url(${icoRightArrow});
  }
  @media (max-width: 640px) {
    text-align: left;
  }
`

const ActivityContainer = styled.div`
  color: white;
  border-top: 1px solid rgba(237, 233, 221, 0.2);
  padding: 0 0 8rem 0;
`

const StickyContainer = styled.div`
  position: sticky;
  color: white;
  top: 0;
  z-index: 9;
  padding: 4rem 0 2rem 0;
  background-color: ${darkGreen};
`

const DateContainer = styled(SmallParagraph)`
  padding: 2rem 0 2rem 0;
`

const Activities = (props) => {
  const [data, isLoaded] = useData(`/${props.type}`)

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const renderList = data.map((d, i) => {
    return (
      <ActivityContainer key={i}>
        <DateContainer dangerouslySetInnerHTML={{ __html: renderHTMLContent(d.ref, 'date') }} />
        <H3 dangerouslySetInnerHTML={{ __html: renderHTMLContent(d.ref, 'title') }} />
        {
          d.url
            ? <LinkContainer href={renderHTMLContent(d.ref, 'url')} target='_blank'>More</LinkContainer>
            : false
        }
      </ActivityContainer>
    )
  }).reverse()

  const render = () => {
    return (
      <div>
        <StickyContainer>
          <H5>{props.type}</H5>
        </StickyContainer>
        {renderList}
      </div>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

Activities.propTypes = {
  type: PropTypes.string.isRequired
}

export default Activities
