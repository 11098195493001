import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Grid = styled.div(({ columns, colGap = '4rem', rowGap = '0', pads }) =>
  css`
    display: grid;
    grid-template-columns: ${columns};
    grid-column-gap: ${colGap};
    grid-row-gap: ${rowGap};
    padding: ${pads};

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
      padding: 0;
    }
  `
)

export const MainGrid = styled.div(({ rowGap, pads = '0' }) =>
  css`
    display: grid;
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: ${pads};

    @media (max-width: 640px) {
      padding: 0;
    }
  `
)

export const GridContent = styled.div(({ size = '1 / -1', margins = '2rem 0' }) =>
  css`
    margin: ${margins};
    grid-column: ${size};

    @media (max-width: 640px) {
      grid-template-columns: 1fr !important;
      grid-column: 1 / -1;
      margin: 1rem 0;
    }
  `
)
