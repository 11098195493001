import React from 'react'
import styled from '@emotion/styled'

// Components
import { Loader } from '../../../components/Loader'
import ProjectsNav from '../../../components/ProjectsNav'
import { H2 } from '../../../components/Titles'
import { Paragraph, Quote } from '../../../components/Texts'
import Video from '../../../components/Video'
import Image from '../../../components/Image'
import Header from '../../../components/Header'
import { MainGrid, Grid, GridContent } from '../../../components/Grids'

// Hooks
import useData from '../../../hooks/useData'

// Constants
import { outerMargin } from '../../../constants'

// Assets
import scaffolderVideo001 from './assets/scaffolder-video-001.mp4'
import scaffolderVideo002 from './assets/scaffolder-video-002.mp4'
import scaffolderVideo003 from './assets/scaffolder-video-003.mp4'
import scaffolderImg001 from './assets/scaffolder-img-001.jpg'

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 4rem 2rem 2rem 2rem;
  }
`

const TitleContainer = styled(H2)`
  margin: 0 0 8rem 0;

  @media (max-width: 640px) {
    margin: 2rem 0 4rem 0;
  }
`

const VideoContainer = styled(GridContent)`
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 0 0;
  }
`

const ImageContainer = styled(GridContent)`
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 4rem 0;
  }
`

const Scaffolder = () => {
  const [data, isLoaded] = useData('/project-scaffolder')

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <>
        <Header page='project-scaffolder' backLink />

        <PageContainer pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>
          <Grid columns='repeat(12, 1fr)'>

            <GridContent size='7 / -1'>
              <TitleContainer dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.title01', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph01', 'content') }} />
            </GridContent>

            <GridContent size='3 / -4'>
              <Quote dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.quote01', 'content') }} />
            </GridContent>

            <VideoContainer size='span 12'>
              <Video src={scaffolderVideo001} height={800} autoPlay highlight />
            </VideoContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph02', 'content') }} />
            </GridContent>

            <ImageContainer size='span 12'>
              <Image src={scaffolderImg001} alt='comm1' height={420} />
            </ImageContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph03', 'content') }} />
            </GridContent>

            <VideoContainer size='span 6'>
              <Video src={scaffolderVideo002} height={340} autoPlay highlight />
            </VideoContainer>

            <VideoContainer size='span 6'>
              <Video src={scaffolderVideo003} height={340} autoPlay highlight />
            </VideoContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph04', 'content') }} />
            </GridContent>

          </Grid>

          <ProjectsNav project='scaffolder' />
        </PageContainer>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Scaffolder
