import React from 'react'
import styled from '@emotion/styled'

// Components
import { Loader } from '../../components/Loader'
import Header from '../../components/Header'
import { MainGrid, Grid, GridContent } from '../../components/Grids'
import { H3 } from '../../components/Titles'

// Constants
import { outerMargin } from '../../constants'

// Hooks
import useData from '../../hooks/useData'

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 6rem 2rem 2rem 2rem;
  }
`

const PlayerContainer = styled(GridContent)`
  margin: 1rem 0;

  > * {
    margin-top: 2rem;
  }

  @media (max-width: 640px) {
    margin: 5rem 0 0.5rem 0;
  }
`

const Music = () => {
  const [data, isLoaded] = useData('/music')

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const renderMusicList = data.filter(d => d.ref === 'music.file').map((d, i) => {
    return (
      <iframe
        key={i}
        title={d.title}
        style={{ border: '0', width: '100%', height: '42px' }}
        src={d.content}
        seamless
      >
        <a href='http://m-operator.bandcamp.com/album/new-horizons'>New Horizons by m-operator</a>
      </iframe>
    )
  })

  const render = () => {
    return (
      <>
        <Header page='music' subtitle />
        <PageContainer className='music' pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>
          <Grid columns='repeat(12, 1fr)'>

            <GridContent size='span 4'>
              <H3 dangerouslySetInnerHTML={{ __html: renderHTMLContent('music.intro', 'content') }} />
            </GridContent>

            <PlayerContainer size='7 / -1'>
              {renderMusicList}
            </PlayerContainer>

          </Grid>
        </PageContainer>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Music
