import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

// Hooks
import useData from '../../hooks/useData'

// Components
import { Loader } from '../Loader'
import { H2, H5 } from '../Titles'
import { TableParagraph } from '../Texts'
import { Grid } from '../Grids'

// Constants
import { darkGreen } from '../../constants'

// Assets
import icoRightArrow from '../../assets/icons/ico.right-arrow.svg'

const LinkContainer = styled(Link)`
  font-family: 'Soehne';
  font-size: 1.8rem;
  font-weight: 400;
  text-decoration: none;
  color: ${darkGreen};
  text-align: right;
  white-space: nowrap;

  &:after {
    content: " ";
    display: inline-block;
    margin-left: 2rem;
    width: 20px;
    height: 12px;
    background-color: ${darkGreen};
    -webkit-mask-image: url(${icoRightArrow});
    mask-image: url(${icoRightArrow});
  }
  @media (max-width: 640px) {
    text-align: left;
    margin-top: 1.5rem;
  }
`

const StickyContainer = styled(Grid)`
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 4rem 0 2rem 0;
  background-color: white;

  @media (max-width: 640px) {
    display: none;
  }
`

const RowContainer = styled(Grid)`
  border-top: 1px solid rgba(174, 183, 177, 0.4);
  padding: 2rem 0 2rem 0;

  @media (max-width: 640px) {
    padding: 2rem 0 2rem 0;
    margin-bottom: 6rem;

    :last-child {
      margin-bottom: 0rem;
    } 
  }
`

const TitleContainer = styled(H2)`
  margin-bottom: 4rem;

  @media (max-width: 640px) {
    margin-bottom: 8rem;
  }
`

const TasksContainer = styled(TableParagraph)`
  @media (max-width: 640px) {
    margin: 1rem 0 4rem 0;
    font-size: 1.3rem;
  }
`

const GoToProjects = styled(Grid)`
  justify-items: left;
  @media (max-width: 640px) {
    margin: 8rem 0 6rem 0;
  }
`

const LastProjects = () => {
  const [data, isLoaded] = useData('/last-projects')

  const renderList = data.map((d, i) => {
    return (
      <RowContainer columns='1fr 2fr 2fr 1fr' key={i}>
        <TableParagraph>{d.date}</TableParagraph>
        <TableParagraph dangerouslySetInnerHTML={{ __html: d.title }} />
        <TasksContainer><em>{d.tasks}</em></TasksContainer>
        <LinkContainer to={`/projects/${d.route}`}>Open project</LinkContainer>
      </RowContainer>
    )
  }).reverse()

  const render = () => {
    return (
      <>
        <Grid columns='repeat(1, 1fr)' pads='0 0 12vH 0'>
          <TitleContainer style={{ gridColumn: '1 / -1' }}>Work highlights</TitleContainer>
        </Grid>
        <ul>
          <StickyContainer columns='1fr 2fr 2fr 1fr'>
            <li><H5>Year</H5></li>
            <li><H5>Project</H5></li>
            <li><H5>Tasks</H5></li>
            <li />
          </StickyContainer>
          {renderList}
        </ul>
        <GoToProjects columns='repeat(1, 1fr)' pads='15vh 0 0 0'>
          <LinkContainer to='/projects'>See all projects</LinkContainer>
        </GoToProjects>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default LastProjects
