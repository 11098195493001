import React from 'react'
import styled from '@emotion/styled'

// Components
import { Loader } from '../../../components/Loader'
import ProjectsNav from '../../../components/ProjectsNav'
import { H2 } from '../../../components/Titles'
import { Paragraph, Quote } from '../../../components/Texts'
import Video from '../../../components/Video'
import Image from '../../../components/Image'
import Header from '../../../components/Header'
import { MainGrid, Grid, GridContent } from '../../../components/Grids'

// Hooks
import useData from '../../../hooks/useData'
import useDeviceDetect from '../../../hooks/useDeviceDetect'

// Constants
import { outerMargin } from '../../../constants'

// Assets
import orangeStoriesVideo001 from './assets/orange-stories-video-001.mp4'
import orangeStoriesImg001 from './assets/orange-stories-img-001.jpg'
import orangeStoriesImg002 from './assets/orange-stories-img-002.jpg'
import orangeStoriesImg003 from './assets/orange-stories-img-003.jpg'

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 4rem 2rem 2rem 2rem;
  }
`

const TitleContainer = styled(H2)`
  margin: 0 0 8rem 0;

  @media (max-width: 640px) {
    margin: 2rem 0 4rem 0;
  }
`

const VideoContainer = styled(GridContent)`
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 0 0;
  }
`

const ImageContainer = styled(GridContent)`
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 4rem 0;
  }
`

const OrangeStories = () => {
  const [data, isLoaded] = useData('/project-orange-stories')
  const { isMobile } = useDeviceDetect()

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <>
        <Header page='project-orange-stories' backLink />

        <PageContainer pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>
          <Grid columns='repeat(12, 1fr)'>

            <GridContent size='7 / -1'>
              <TitleContainer dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.title01', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph01', 'content') }} />
            </GridContent>

            <VideoContainer size='span 12'>
              <Video src={orangeStoriesVideo001} highlight autoPlay height={800} />
            </VideoContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph02', 'content') }} />
            </GridContent>

            <GridContent size='3 / -4'>
              <Quote dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.quote01', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph03', 'content') }} />
            </GridContent>

            <ImageContainer size='span 12'>
              <Image src={orangeStoriesImg002} alt='comm1' height={isMobile ? 400 : 800} />
            </ImageContainer>

            <GridContent size='7 / -1' margins='0 0 6rem 0'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph04', 'content') }} />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={orangeStoriesImg001} alt='comm1' height={380} />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={orangeStoriesImg003} alt='comm1' height={380} />
            </GridContent>

          </Grid>

          <ProjectsNav project='orange-stories' />
        </PageContainer>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default OrangeStories
