import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// Components
import { H3 } from '../Titles'

// Constants
import { darkGreen, lightGreen } from '../../constants'

const LoaderContainer = styled.div`
  display: grid;
  width: 100vW;
  height: 100vH;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: ${darkGreen};
  color: white;
  place-items: center;
`

const PlaceHolderContainer = styled.div`
  width: 100%;
  height: ${props => props.height + 'px'};
  background-color: ${lightGreen};
`

export const Loader = () => {
  return (
    <LoaderContainer>
      <H3>Loading...</H3>
    </LoaderContainer>
  )
}

export const PlaceHolder = (props) => {
  return (
    <PlaceHolderContainer {...props} />
  )
}

PlaceHolder.propTypes = {
  highlight: PropTypes.bool,
  height: PropTypes.number.isRequired
}
