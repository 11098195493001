import React from 'react'
import styled from '@emotion/styled'

// Components
import { Loader } from '../../components/Loader'
import { Paragraph } from '../../components/Texts'
import Header from '../../components/Header'
import { H2, H3, H5 } from '../../components/Titles'
import { MainGrid, Grid, GridContent } from '../../components/Grids'
import Image from '../../components/Image'

// Constants
import { outerMargin } from '../../constants'

// Hooks
import useData from '../../hooks/useData'

// Assets
import imageProfile from './assets/img-profile.jpg'

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 4rem 2rem 2rem 2rem;
  }
`

const ULContainer = styled(GridContent)`
  margin-bottom: 10rem;

  @media (max-width: 640px) {
    margin: 4rem 0 8rem 0;
  }

  li {
    margin-bottom: 2rem;
  }
`

const TitleContainer = styled(H2)`
  margin: 0 0 8rem 0;

  @media (max-width: 640px) {
    margin: 0 0 4rem 0;
  }
`

const ProfilePic = styled(Image)`
  margin-top: 8rem;

  @media (max-width: 640px) {
    margin-top: 4rem;
    
  }
`

const About = () => {
  const [data, isLoaded] = useData('/about')

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const renderListExperience = data.filter(d => d.ref === 'about.experience').map((d, i) => {
    return (
      <li key={i}><H3 dangerouslySetInnerHTML={{ __html: d.content }} /></li>
    )
  }).reverse()

  const renderListLanguage = data.filter(d => d.ref === 'about.language').map((d, i) => {
    return (
      <ol key={i}><H3 dangerouslySetInnerHTML={{ __html: d.content }} /></ol>
    )
  }).reverse()

  const renderListAwardsAndApparences = data.filter(d => d.ref === 'about.awards').map((d, i) => {
    return (
      <li key={i}><H3 dangerouslySetInnerHTML={{ __html: d.content }} /></li>
    )
  }).reverse()

  const render = () => {
    return (
      <>
        <Header page='about' subtitle />
        <PageContainer pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>
          <Grid columns='repeat(12, 1fr)'>

            <GridContent size='span 6'>
              <ULContainer size='1 / -1'>
                <GridContent size='span 6'>
                  <H5>Experience</H5>
                </GridContent>
                <ul>{renderListExperience}</ul>
              </ULContainer>

              <ULContainer size='1 / -1'>
                <GridContent size='span 6'>
                  <H5>Languages</H5>
                </GridContent>
                <ul>{renderListLanguage}</ul>
              </ULContainer>

              <ULContainer size='1 / -1'>
                <GridContent size='span 6'>
                  <H5>Awards and apparences</H5>
                </GridContent>
                <ul>{renderListAwardsAndApparences}</ul>
              </ULContainer>
            </GridContent>

            <GridContent size='span 6'>
              <GridContent size='span 6'>
                <TitleContainer>Bio</TitleContainer>
              </GridContent>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('about.intro', 'content') }} />
            </GridContent>

            <GridContent size='1 / -1'>
              <ProfilePic src={imageProfile} alt='comm1' height={600} />
            </GridContent>

          </Grid>
        </PageContainer>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default About
