import React, { useState } from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useSpring, animated } from 'react-spring'

// Components
import { PlaceHolder } from '../Loader'

const ImageContainer = styled.div`
  position: relative;
  width: ${props => props.highlight ? '100%' : '100%'};
  height: ${props => props.height + 'px'};
  overflow: hidden;

  @media (max-width: 640px) {
    height: ${props => props.height / 1.8 + 'px'};
  }
`

const ImageElement = styled(animated.div)(({ highlight, height, src, type = 'cover' }) =>
  css`
    position: absolute;
    width: ${highlight ? '100%' : '100%'};
    height: ${height + 'px'};
    background: url(${src}) no-repeat center center;
    background-size: ${type || 'cover'};
    border-radius: 2px;

    @media 
        (-webkit-min-device-pixel-ratio:1.5),
        (min--moz-device-pixel-ratio:1.5),
        (-o-min-device-pixel-ratio:3/2),
        (min-resolution:1.5dppx)
    {
        background: url(${src}) no-repeat center center;
        background-size: ${type};
    }

    @media (max-width: 640px) {
      height: ${height / 1.8 + 'px'};
    }
  `
)

const Image = (props) => {
  const [hover, setHover] = useState(false)
  const [anim, setAnim] = useSpring(() => ({ config: { mass: 1, tension: 170, friction: 26 } }))

  if (props.animated === 1) {
    setAnim({ transform: hover ? 'scale3d(1.05, 1.05, 1.05)' : 'scale3d(1, 1, 1)' })
  }

  return (
    <LazyLoad
      height={props.height}
      once
      offset={[-100, 0]}
      placeholder={<PlaceHolder highlight={props.highlight === 1} height={props.height} />}
    >
      <ImageContainer height={props.height} highlight={props.highlight === 1}>
        <ImageElement
          {...props}
          style={anim}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      </ImageContainer>
    </LazyLoad>
  )
}

export default Image

Image.propTypes = {
  height: PropTypes.number.isRequired,
  type: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  highlight: PropTypes.number,
  animated: PropTypes.number
}
