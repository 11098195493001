import React from 'react'
import styled from '@emotion/styled'

// Components
import Header from '../../components/Header'
import { MainGrid, Grid, GridContent } from '../../components/Grids'
import { H5, H3 } from '../../components/Titles'
import Image from '../../components/Image'

// Constants
import { outerMargin } from '../../constants'

// Assets
import japan001 from './assets/japan_001.jpg'
import japan002 from './assets/japan_002.jpg'
import japan003 from './assets/japan_003.jpg'
import japan004 from './assets/japan_004.jpg'
import japan005 from './assets/japan_005.jpg'
import japan006 from './assets/japan_006.jpg'
import japan007 from './assets/japan_007.jpg'
import japan008 from './assets/japan_008.jpg'
import iceland001 from './assets/iceland_001.jpg'
import iceland002 from './assets/iceland_002.jpg'
import iceland003 from './assets/iceland_003.jpg'
import iceland004 from './assets/iceland_004.jpg'
import iceland005 from './assets/iceland_005.jpg'
import iceland006 from './assets/iceland_006.jpg'
import iceland007 from './assets/iceland_007.jpg'
import iceland008 from './assets/iceland_008.jpg'
import iceland009 from './assets/iceland_009.jpg'
import iceland010 from './assets/iceland_010.jpg'
import iceland011 from './assets/iceland_011.jpg'

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 6rem 2rem 2rem 2rem;
  }
`

const CategoryContainer = styled.div`
  padding: 15vh 0 8rem 0;

  &.first-category {
    padding: 0 0 8rem 0;
  }

  @media (max-width: 640px) {
    padding: 15vh 0 4rem 0;

    &.first-category {
      padding: 2rem 0 4rem 0;
    }
  }
`

const CategoryH3 = styled(H3)`
  margin: -1.5rem 0 0 0;
`

const Photography = () => {
  const render = () => {
    return (
      <>
        <Header page='photography' subtitle />

        <PageContainer pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>

          <div>
            <CategoryContainer className='first-category'>
              <H5>2018</H5>
              <CategoryH3>Iceland</CategoryH3>
            </CategoryContainer>
            <Grid columns='repeat(12, 1fr)'>

              <GridContent size='span 12'>
                <Image src={iceland001} alt='comm1' height={600} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={iceland002} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={iceland003} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 4'>
                <Image src={iceland004} alt='comm1' height={240} type='cover' />
              </GridContent>

              <GridContent size='span 4'>
                <Image src={iceland005} alt='comm1' height={240} type='cover' />
              </GridContent>

              <GridContent size='span 4'>
                <Image src={iceland006} alt='comm1' height={240} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={iceland011} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={iceland007} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={iceland010} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={iceland009} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 12'>
                <Image src={iceland008} alt='comm1' height={600} type='cover' />
              </GridContent>

            </Grid>
          </div>

          <div>
            <CategoryContainer>
              <H5>2017</H5>
              <CategoryH3>Japan</CategoryH3>
            </CategoryContainer>
            <Grid columns='repeat(12, 1fr)'>
              <GridContent size='span 12'>
                <Image src={japan001} alt='comm1' height={680} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={japan005} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={japan003} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={japan004} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={japan002} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={japan006} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 6'>
                <Image src={japan007} alt='comm1' height={400} type='cover' />
              </GridContent>

              <GridContent size='span 12'>
                <Image src={japan008} alt='comm1' height={600} type='cover' />
              </GridContent>
            </Grid>
          </div>

        </PageContainer>
      </>
    )
  }

  return (
    render()
  )
}

export default Photography
