import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// Components
import Image from '../Image'
import { Grid } from '../Grids'
import { SmallParagraph, ProjectCardP } from '../Texts'

const MetasContainer = styled.div`
  align-self: end;
  margin-top: 2rem;

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
`

const ProjectCard = (props) => {
  return (
    <>
      <Grid columns={props.highlight ? '2fr 2fr' : '1fr'} colGap='4rem'>
        {
          props.highlight
            ? <>
              <Link to={`/projects/${props.route}`}>
                <Image
                  src={props.cover}
                  alt={props.title}
                  highlight={props.highlight ? 1 : 0}
                  animated={1}
                  type='cover'
                  height={props.highlight ? 380 : 340}
                />
              </Link>
              <div style={{ display: 'grid' }}>
                <ProjectCardP highlight={props.highlight}>{props.excerpt}</ProjectCardP>
                <MetasContainer>
                  <SmallParagraph style={{ float: 'left', textAlign: 'left' }}>Tasks: {props.metas}</SmallParagraph>
                  <SmallParagraph style={{ float: 'right', textAlign: 'right' }}>{props.date}</SmallParagraph>
                </MetasContainer>
              </div>
            </>
            : <Grid columns='1fr' style={{ gridTemplateRows: '8rem auto auto' }}>
              <ProjectCardP highlight={props.highlight}>{props.excerpt}</ProjectCardP>
              <Link to={`/projects/${props.route}`}>
                <Image
                  src={props.cover}
                  alt={props.title}
                  highlight={props.highlight ? 1 : 0}
                  animated={1}
                  type='cover'
                  height={props.highlight ? 380 : 340}
                />
              </Link>
              <MetasContainer>
                <SmallParagraph style={{ float: 'left', textAlign: 'left' }}>Tasks: {props.metas}</SmallParagraph>
                <SmallParagraph style={{ float: 'right', textAlign: 'right' }}>{props.date}</SmallParagraph>
              </MetasContainer>
            </Grid>
        }
      </Grid>
    </>
  )
}

ProjectCard.propTypes = {
  highlight: PropTypes.bool,
  cover: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  metas: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
}

export default ProjectCard
