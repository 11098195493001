import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

// Pages
import About from './pages/About'
import Home from './pages/Home'
import Projects from './pages/Projects'
import Music from './pages/Music'
import Photography from './pages/Photography'
import Commute from './pages/Projects/Commute'
import VisualGrammar from './pages/Projects/VisualGrammar'
import Scaffolder from './pages/Projects/Scaffolder'
import MOperator from './pages/Projects/MOperator'
import SkillsMapping from './pages/Projects/SkillsMapping'
import Companion from './pages/Projects/Companion'
import EnergyFlexibilitySimulator from './pages/Projects/EnergyFlexibilitySimulator'
import MappingConsumerData from './pages/Projects/MappingConsumerData'
import OrangeStories from './pages/Projects/OrangeStories'
import Orat from './pages/Projects/Orat'

// Components
import GlobalStyles from './components/GlobalStyles'
import Nav from './components/Nav'
import Footer from './components/Footer'
import { MainGrid } from './components/Grids'

const App = () => {
  return (
    <Router>
      <GlobalStyles />
      <MainGrid>
        <Nav />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' exact component={About} />
          <Route path='/music' exact component={Music} />
          <Route path='/photography' exact component={Photography} />
          <Route path='/projects' exact component={Projects} />
          <Route path='/projects/commute' exact component={Commute} />
          <Route path='/projects/visual-grammar' exact component={VisualGrammar} />
          <Route path='/projects/scaffolder' exact component={Scaffolder} />
          <Route path='/projects/orat' exact component={Orat} />
          <Route path='/projects/m-operator' exact component={MOperator} />
          <Route path='/projects/skills-mapping' exact component={SkillsMapping} />
          <Route path='/projects/companion' exact component={Companion} />
          <Route path='/projects/energy-flexibility-simulator' exact component={EnergyFlexibilitySimulator} />
          <Route path='/projects/mapping-consumer-data' exact component={MappingConsumerData} />
          <Route path='/projects/orange-stories' exact component={OrangeStories} />
        </Switch>
        <Footer />
      </MainGrid>
    </Router>
  )
}

export default App
