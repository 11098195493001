import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Constants
import { darkGreen, outerMargin } from '../../constants'

// Components
import { Loader } from '../Loader'
import HeaderVisual from '../HeaderVisual'
import { H1, H3 } from '../Titles'
import { Paragraph } from '../Texts'
import { MainGrid, Grid, GridContent } from '../Grids'

// Hooks
import useData from '../../hooks/useData'

// Assets
import icoLeftArrowWhite from '../../assets/icons/ico.left-arrow-white.svg'

const HeaderCover = styled.div`
  display: grid;
  align-content: center;
  background-color: ${darkGreen};
  padding: 0 14rem;
  height: calc(100vh - 14rem);
  color: white;

  @media (max-width: 640px) {
    height: auto;
    padding: 8rem ${outerMargin};
  }

  @media (max-width: 320px) {
    height: auto;
    padding: 4rem ${outerMargin};
  }
`

const LinkContainer = styled(Link)`
  font-family: 'Soehne';
  font-size: 1.8rem;
  font-weight: 400;
  text-decoration: none;
  display: block;
  color: white;

  &:before {
    content: " ";
    display: inline-block;
    width: 30px;
    height: 20px;
    background-color: white;
    background: url(${icoLeftArrowWhite}) no-repeat bottom left;
  }

  @media (max-width: 640px) {
    margin: 0 0 4rem 0;
  }
`

const TitleContainer = styled(H1)`
  @media (max-width: 640px) {
    font-size: 4.2rem;
    margin: 0 0 4rem 0;
  }
`

const SubTitleContainer = styled(GridContent)`
  @media (max-width: 640px) {
    margin: 4rem 0 4rem 0;
  }
`

const Header = (props) => {
  const [data, isLoaded] = useData('/headers')

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <>
        <MainGrid className='header-cover'>
          <HeaderCover>
            {
              props.cover
                ? <HeaderVisual coverIndex={props.cover} />
                : <TitleContainer dangerouslySetInnerHTML={{ __html: renderHTMLContent(`${props.page}.title`, 'content') }} />
            }
            <Grid columns='repeat(2, 1fr)' pads='10vh 0' style={{ zIndex: 1 }}>
              {
                props.subtitle
                  ? <SubTitleContainer size='1 / 2'><H3 dangerouslySetInnerHTML={{ __html: renderHTMLContent(`${props.page}.subtitle`, 'content') }} /></SubTitleContainer>
                  : false
              }
              {
                props.backLink
                  ? <GridContent size='1 / 2'><LinkContainer to='/projects'>Back to projects list</LinkContainer></GridContent>
                  : false
              }
              {
                props.cover
                  ? false
                  : <GridContent size='2 / -2' style={{ zIndex: 1 }}>
                    <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent(`${props.page}.content`, 'content') }} />
                  </GridContent>
              }
            </Grid>
          </HeaderCover>
        </MainGrid>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

Header.propTypes = {
  page: PropTypes.string.isRequired,
  backLink: PropTypes.bool,
  subtitle: PropTypes.bool,
  cover: PropTypes.string
}

export default Header
