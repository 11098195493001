import React from 'react'
import styled from '@emotion/styled'

// Components
import LastProjects from '../../components/LastProjects'
import Activities from '../../components/Activities'
import Header from '../../components/Header'
import { Grid, MainGrid } from '../../components/Grids'
import { H2 } from '../../components/Titles'

// Constants
import { outerMargin, darkGreen, beige } from '../../constants'

const TitleContainer = styled(Grid)`
  @media (max-width: 640px) {
    margin: 4rem 0;
  }
`

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    margin: 8rem 2rem 6rem 2rem;
  }
`

const ActivitiesContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 6rem 2rem 2rem 2rem;
  }
`

const Home = () => {
  const render = () => {
    return (
      <>
        <Header page='home' cover='home' subtitle />

        <PageContainer pads={`20vh calc(${outerMargin} + 4rem) 20vh calc(${outerMargin} + 4rem)`}>
          <LastProjects />
        </PageContainer>

        <ActivitiesContainer pads={`20vh calc(${outerMargin} + 4rem)`} style={{ backgroundColor: `${darkGreen}` }}>
          <TitleContainer columns='repeat(2, 1fr)' pads='0 0 15vh 0'>
            <H2 style={{ gridColumn: '1 / -1', color: beige }}>Activities</H2>
          </TitleContainer>
          <Grid columns='repeat(3, 1fr)'>
            <Activities type='talks' />
            <Activities type='teaching' />
            <Activities type='awards' />
          </Grid>
        </ActivitiesContainer>
      </>
    )
  }

  return (
    render()
  )
}

export default Home
