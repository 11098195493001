import React from 'react'
import styled from '@emotion/styled'

// Components
import { Loader } from '../../../components/Loader'
import ProjectsNav from '../../../components/ProjectsNav'
import { H2 } from '../../../components/Titles'
import { Paragraph, Quote } from '../../../components/Texts'
import Video from '../../../components/Video'
import Image from '../../../components/Image'
import Header from '../../../components/Header'
import { MainGrid, Grid, GridContent } from '../../../components/Grids'

// Hooks
import useData from '../../../hooks/useData'

// Constants
import { outerMargin } from '../../../constants'

// Assets
import mappingConsumerDataVideo001 from './assets/mapping-consumer-data-video-001.mp4'
import mappingConsumerDataImg001 from './assets/mapping-consumer-data-img-001.jpg'
import mappingConsumerDataImg002 from './assets/mapping-consumer-data-img-002.jpg'
import mappingConsumerDataImg003 from './assets/mapping-consumer-data-img-003.jpg'
import mappingConsumerDataImg004 from './assets/mapping-consumer-data-img-004.jpg'
import mappingConsumerDataImg005 from './assets/mapping-consumer-data-img-005.jpg'
import mappingConsumerDataImg006 from './assets/mapping-consumer-data-img-006.jpg'

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 4rem 2rem 2rem 2rem;
  }
`

const TitleContainer = styled(H2)`
  margin: 0 0 8rem 0;

  @media (max-width: 640px) {
    margin: 2rem 0 4rem 0;
  }
`

const VideoContainer = styled(GridContent)`
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 0 0;
  }
`

const MappingConsumerData = () => {
  const [data, isLoaded] = useData('/project-mapping-consumer-data')

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <>
        <Header page='project-mapping-consumer-data' backLink />

        <PageContainer pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>
          <Grid columns='repeat(12, 1fr)'>

            <GridContent size='7 / -1'>
              <TitleContainer dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.title01', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph01', 'content') }} />
            </GridContent>

            <VideoContainer size='span 12'>
              <Video src={mappingConsumerDataVideo001} highlight autoPlay height={800} />
            </VideoContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph02', 'content') }} />
            </GridContent>

            <GridContent size='3 / -4'>
              <Quote dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.quote01', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph03', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph04', 'content') }} />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={mappingConsumerDataImg001} alt='comm1' height={380} type='contain' />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={mappingConsumerDataImg002} alt='comm1' height={380} type='contain' />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={mappingConsumerDataImg003} alt='comm1' height={380} type='contain' />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={mappingConsumerDataImg004} alt='comm1' height={380} type='contain' />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={mappingConsumerDataImg005} alt='comm1' height={380} type='contain' />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={mappingConsumerDataImg006} alt='comm1' height={380} type='contain' />
            </GridContent>

          </Grid>

          <ProjectsNav project='mapping-consumer-data' />
        </PageContainer>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default MappingConsumerData
