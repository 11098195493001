import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { NavLink } from 'react-router-dom'

// Constants
import { darkGreen, mediumGreen } from '../../constants'

export const Paragraph = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 165%;
`

export const SmallParagraph = styled.p`
  color: ${mediumGreen};
  font-family: 'Soehne';
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.4;
`

export const TableParagraph = styled.p`
  color: ${darkGreen};
  font-family: 'Soehne';
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 140%;
  padding-right: 2rem;

  @media (max-width: 640px) {
    margin-bottom: 0.5rem;
  }
`

export const ProjectCardP = styled.p(({ highlight }) =>
  css`
    color: ${darkGreen};
    font-size: ${highlight ? '2.4rem' : '1.8rem'};
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: ${highlight ? '10' : '2'};
    -webkit-box-orient: vertical;  
    overflow: hidden;
    margin: ${highlight ? '0 0 0 0;' : '0 0 2rem 0;'};

    @media (max-width: 640px) {
      margin: 1rem 0;
    }
  `
)

export const Quote = styled.p`
  font-family: 'Soehne';
  color: ${darkGreen};
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 140%;
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 4rem 0;
  }
`

export const Link = styled(NavLink)`
  font-family: 'Soehne';
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }

  &.active {
    &:after {
      text-align: center;
      font-size: 1.3rem;
      margin: 1.3rem auto;
      content: "●";
      display: block;
      width: 10px;
      height: 10px;
    }
  }

  @media (max-width: 640px) {
    text-align: left;

    &.active {
      &:after {
        text-align: left;
        font-size: 1.3rem;
        margin: 0 1rem;
        content: "●";
        display: inline-block;

      }
    }
  }
`
