import React from 'react'
import styled from '@emotion/styled'

// Components
import { Loader } from '../../../components/Loader'
import ProjectsNav from '../../../components/ProjectsNav'
import { H2 } from '../../../components/Titles'
import { Paragraph, Quote } from '../../../components/Texts'
import Video from '../../../components/Video'
import Image from '../../../components/Image'
import Header from '../../../components/Header'
import { MainGrid, Grid, GridContent } from '../../../components/Grids'

// Hooks
import useData from '../../../hooks/useData'

// Constants
import { outerMargin } from '../../../constants'

// Assets
import energyFlexibilitySimulatorVideo001 from './assets/energy-flexibility-simulator-video-001.mp4'
import energyFlexibilitySimulatorImg001 from './assets/energy-flexibility-simulator-img-001.jpg'
import energyFlexibilitySimulatorImg002 from './assets/energy-flexibility-simulator-img-002.jpg'
import energyFlexibilitySimulatorImg003 from './assets/energy-flexibility-simulator-img-003.jpg'
import energyFlexibilitySimulatorImg004 from './assets/energy-flexibility-simulator-img-004.jpg'
import energyFlexibilitySimulatorImg005 from './assets/energy-flexibility-simulator-img-005.jpg'
import energyFlexibilitySimulatorImg006 from './assets/energy-flexibility-simulator-img-006.jpg'

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 4rem 2rem 2rem 2rem;
  }
`

const TitleContainer = styled(H2)`
  margin: 0 0 8rem 0;

  @media (max-width: 640px) {
    margin: 2rem 0 4rem 0;
  }
`

const VideoContainer = styled(GridContent)`
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 0 0;
  }
`

const ImageContainer = styled(GridContent)`
  margin: 8rem 0;

  @media (max-width: 640px) {
    margin: 0rem 0;
  }
`

const EnergyFlexibilitySimulator = () => {
  const [data, isLoaded] = useData('/project-energy-flexibility-simulator')

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <>
        <Header page='project-energy-flexibility-simulator' backLink />

        <PageContainer pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>
          <Grid columns='repeat(12, 1fr)'>

            <GridContent size='7 / -1'>
              <TitleContainer dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.title01', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph01', 'content') }} />
            </GridContent>

            <VideoContainer size='1 / -1'>
              <Video src={energyFlexibilitySimulatorVideo001} height={800} autoPlay />
            </VideoContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph02', 'content') }} />
            </GridContent>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph03', 'content') }} />
            </GridContent>

            <GridContent size='3 / -4'>
              <Quote dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.quote01', 'content') }} />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={energyFlexibilitySimulatorImg005} alt='comm1' height={380} type='contain' />
            </GridContent>

            <GridContent size='span 6'>
              <Image src={energyFlexibilitySimulatorImg002} alt='comm1' height={380} type='contain' />
            </GridContent>

            <ImageContainer size='span 6'>
              <Image src={energyFlexibilitySimulatorImg006} alt='comm1' height={380} type='contain' />
            </ImageContainer>

            <ImageContainer size='span 6'>
              <Image src={energyFlexibilitySimulatorImg004} alt='comm1' height={380} type='contain' />
            </ImageContainer>

            <GridContent size='7 / -1'>
              <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('project.paragraph04', 'content') }} />
            </GridContent>

            <ImageContainer size='span 6'>
              <Image src={energyFlexibilitySimulatorImg001} alt='comm1' height={380} type='contain' />
            </ImageContainer>

            <ImageContainer size='span 6'>
              <Image src={energyFlexibilitySimulatorImg003} alt='comm1' height={380} type='contain' />
            </ImageContainer>

          </Grid>

          <ProjectsNav project='energy-flexibility-simulator' />
        </PageContainer>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default EnergyFlexibilitySimulator
