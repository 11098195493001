// Colors
export const darkGreen = '#000000'// '#304037'
export const mediumGreen = '#777777'
export const lightGreen = '#222222'
export const pureWhite = '#FFFFFF'
export const beige = '#ffffff' // '#EDE9DD'

// Spacing
export const small = '4rem'
export const regular = '6rem'
export const medium = '8rem'
export const outerMargin = '6vw'
