import { useState, useEffect } from 'react'

// API
import * as jsonData from '../api/db.json'

export default (page) => {
  const [data, setData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      const res = await jsonData.default[page.slice(1, page.length)]
      const data = res.map(d => d)
      setData(data)
    }

    fetch().then(() => setIsLoaded(true))
  }, [page])

  return [data, isLoaded]
}
