import React from 'react'
import styled from '@emotion/styled'

// Hooks
import useData from '../../hooks/useData'

// Components
import { Loader } from '../../components/Loader'
import ProjectCard from '../../components/ProjectCard'
import Header from '../../components/Header'
import { MainGrid, Grid } from '../../components/Grids'
import { H5, H3 } from '../../components/Titles'

// Constants
import { outerMargin } from '../../constants'

// Assets projects
import imgCoverCommute from './Commute/assets/img-cover-01.jpg'
import imgCoverMappingConsumerData from './MappingConsumerData/assets/img-cover-01.png'
import imgCoverScaffolder from './Scaffolder/assets/img-cover-01.png'
import imgCoverVisualGrammar from './VisualGrammar/assets/img-cover-01.png'
import imgCoverMOperator from './MOperator/assets/img-cover-01.png'
import imgCoverOrat from './Orat/assets/img-cover-01.png'
import imgCoverSkillsMapping from './SkillsMapping/assets/img-cover-01.png'
import imgCoverCompanion from './Companion/assets/img-cover-01.png'
import imgCoverOrangeStories from './OrangeStories/assets/img-cover-01.png'
import imgCoverEnergyFlexibilitySimulator from './EnergyFlexibilitySimulator/assets/img-cover-01.png'

const covers = {
  imgCoverCommute,
  imgCoverMappingConsumerData,
  imgCoverScaffolder,
  imgCoverVisualGrammar,
  imgCoverMOperator,
  imgCoverOrat,
  imgCoverSkillsMapping,
  imgCoverCompanion,
  imgCoverOrangeStories,
  imgCoverEnergyFlexibilitySimulator
}

const PageContainer = styled(MainGrid)`
  @media (max-width: 640px) {
    padding: 6rem 2rem 2rem 2rem;
  }
`

const CategoryContainer = styled.div`
  padding: 15vh 0 8rem 0;

  &.first-category {
    padding: 0 0 8rem 0;
  }

  @media (max-width: 640px) {
    padding: 5vh 0 4rem 0;

    &.first-category {
      padding: 2rem 0 4rem 0;
    }
  }
`

const CategoryH3 = styled(H3)`
  margin: -1.5rem 0 0 0;
`

const CardsContainer = styled(Grid)`
  @media (max-width: 640px) {
    > div {
      margin: 0 0 6rem 0;
    }
  }
`

const Projects = () => {
  const [data, isLoaded] = useData('/projects')

  const card = (d, i) => {
    return (
      <ProjectCard
        key={i}
        highlight={d.highlight}
        date={d.date}
        metas={d.metas}
        title={d.title}
        excerpt={d.excerpt}
        cover={covers[d.cover]}
        route={d.route}
      />
    )
  }

  const render = () => {
    return (
      <>
        <Header page='projects' subtitle />

        <PageContainer pads={`15vh calc(${outerMargin} + 4rem) 0 calc(${outerMargin} + 4rem)`}>

          <div>
            <CategoryContainer className='first-category'>
              <H5>Experiment</H5>
              <CategoryH3>Research and development</CategoryH3>
            </CategoryContainer>

            <CardsContainer columns='repeat(1, 1fr)' pads='0 0 12rem 0'>
              {data.filter(d => d.category === 'experiment').map((d, i) => card(d, i)).reverse()}
            </CardsContainer>

            <CardsContainer columns='repeat(2, 1fr)'>
              {data.filter(d => d.category === 'tool').map((d, i) => card(d, i)).reverse()}
            </CardsContainer>
          </div>

          <div>
            <CategoryContainer>
              <H5>Side project</H5>
              <CategoryH3>Concepts & exploration</CategoryH3>
            </CategoryContainer>
            <CardsContainer columns='repeat(2, 1fr)'>
              {data.filter(d => d.category === 'side project').map((d, i) => card(d, i)).reverse()}
            </CardsContainer>
          </div>

          <div>
            <CategoryContainer>
              <H5>Tools</H5>
              <CategoryH3>Products</CategoryH3>
            </CategoryContainer>
            <CardsContainer columns='repeat(2, 1fr)'>
              {data.filter(d => d.category === 'moc').map((d, i) => card(d, i)).reverse()}
            </CardsContainer>
          </div>

          <div>
            <CategoryContainer>
              <H5>Communication</H5>
              <CategoryH3>Educational and public</CategoryH3>
            </CategoryContainer>
            <CardsContainer columns='repeat(3, 1fr)'>
              {data.filter(d => d.category === 'client').map((d, i) => card(d, i)).reverse()}
            </CardsContainer>
          </div>

        </PageContainer>
      </>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Projects
