import React from 'react'
import styled from '@emotion/styled'

// Hooks
import useData from '../../hooks/useData'

// Components
import { Loader } from '../Loader'
import { MainGrid, Grid } from '../Grids'
import { Link } from '../Texts'

// Constants
import { darkGreen } from '../../constants'

// Assets
import icoRightArrow from '../../assets/icons/ico.right-arrow.svg'
import icoLeftArrow from '../../assets/icons/ico.left-arrow.svg'

const NavContainer = styled(Grid)`
  height: 25vh;
  border-bottom: 1px solid rgba(174, 183, 177, 0.4);
  justify-items: left;
  align-items: center;
  padding-bottom: 2rem;

  a {
    color: ${darkGreen};
  }

  @media (max-width: 640px) {
    height: 8vh;
    border: 0;
    margin: 10rem 2rem 0 2rem;
}
`

const LinkPrev = styled(Link)`
  font-size: 1.8rem;

  &:before {
    content: " ";
    display: inline-block;
    margin-right: 2rem;
    width: 20px;
    height: 12px;
    background-color: ${darkGreen};
    -webkit-mask-image: url(${icoLeftArrow});
    mask-image: url(${icoLeftArrow});
  } 
`

const LinkNext = styled(Link)`
  font-size: 1.8rem;

  &:before {
    content: " ";
    display: inline-block;
    margin-right: 2rem;
    width: 20px;
    height: 12px;
    background-color: ${darkGreen};
    -webkit-mask-image: url(${icoRightArrow});
    mask-image: url(${icoRightArrow});
  }
`

const ProjectsNav = ({ project }) => {
  const [data, isLoaded] = useData('/projects')

  const currentId = parseInt(data.filter(d => d.route === project).map(d => +d.id))
  const prevProject = data[currentId - 1]
  const nextProject = data[currentId + 1]

  const render = () => {
    return (
      <MainGrid pads='0 0'>
        <NavContainer columns='repeat(2, 1fr)' colGap='4rem'>
          {currentId === 0 ? null : <LinkPrev exact to={prevProject.route}><em>Previous project:</em> {prevProject.title}</LinkPrev>}
          {currentId === data.length - 1 ? null : <LinkNext exact to={nextProject.route}><em>Next project:</em> {nextProject.title}</LinkNext>}
        </NavContainer>
      </MainGrid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default ProjectsNav
