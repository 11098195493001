import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

// Hooks
import useData from '../../hooks/useData'

// Components
import { Loader } from '../Loader'
import { MainGrid, Grid } from '../Grids'
import { Link } from '../Texts'

// Constants
import { darkGreen } from '../../constants'

const Logo = styled.div`
  margin-top: -0.2rem;
  margin-bottom: 2rem;
  z-index: 1;

  a {
    font-family: 'Soehne';
    font-size: 1.5rem;
    text-decoration: none;
    
    &:hover {
      text-decoration: none;
    }
  }
`

const MenuContainer = styled(Grid)`
  justify-self: right;
  z-index: 9;

  a {
    color: white;
    
    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 640px) {
    justify-self: left;
    margin: 3rem 0 2rem 40vw;

    a {
      font-size: 2.1rem;
      line-height: 1.3;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`

const NavContainer = styled.div`
  background-color: ${darkGreen};
  padding: 4rem;
  height: 60px;

  @media (max-width: 640px) {
    height: auto;
    padding: 2rem 2rem 0rem 2rem;
  }
`

const Nav = () => {
  const [data, isLoaded] = useData('/nav')

  const linksList = data.filter(d => d.type === 'internal').map((d, i) => {
    return (
      <Link exact key={i} to={d.route}>{d.title}</Link>
    )
  })

  const render = () => {
    return (
      <MainGrid>
        <NavContainer>
          <Grid columns='repeat(2, 1fr)'>
            <Logo><NavLink exact to='/'>Ludovic Riffault</NavLink></Logo>
            <MenuContainer columns='repeat(5, 1fr)' colGap='2rem'>
              {linksList}
            </MenuContainer>
          </Grid>
        </NavContainer>
      </MainGrid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

export default Nav
