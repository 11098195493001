import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'

// Components
import { Loader } from '../Loader'
import { Grid } from '../Grids'
import { Paragraph } from '../Texts'

// Hooks
import useData from '../../hooks/useData'

// Constants
import { darkGreen } from '../../constants'
import { H4 } from '../Titles'

// Assets
import icoClose from '../../assets/icons/ico.close.svg'

const ModalContainer = styled.div`
    width: 80vW;
    max-width: 1000px;
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #F5F5F5;

    @media (max-width: 640px) {
        width: 100vW;
        padding: 2rem;

        .colophonTitle {
          margin-top: 2rem;
        }

        p {
          margin-top: 4rem;
        }
    }
`

const CloseButton = styled.div`
    margin: 4rem;
    float: right;
    cursor: pointer;
    width: 21px;
    height: 21px;
    background-color: ${darkGreen};
    -webkit-mask-image: url(${icoClose});
    mask-image: url(${icoClose});

    @media (max-width: 640px) {
      margin: 2rem 4rem;
  }
`

const ColophonContent = () => {
  const [data, isLoaded] = useData('/colophon')

  const renderHTMLContent = (ref, element) => {
    return data.filter(d => d.ref === ref).map(d => d[element])
  }

  const render = () => {
    return (
      <Grid columns='1fr 4fr' colGap='10rem' pads='10vh 2vw 10vh 10vw'>
        <H4 className='colophonTitle'>Colophon</H4>
        <Paragraph dangerouslySetInnerHTML={{ __html: renderHTMLContent('colophon.intro', 'content') }} />
      </Grid>
    )
  }

  return (
    isLoaded ? render() : <Loader />
  )
}

const Modal = ({ hide }) => {
  const animIn = useSpring({ opacity: 1, from: { opacity: 0 } })

  return (
    <animated.div style={animIn}>
      <ModalContainer>
        <CloseButton data-dismiss='modal' onClick={hide}>
          <span>Close</span>
        </CloseButton>
        <ColophonContent />
      </ModalContainer>
    </animated.div>
  )
}

const Portal = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(<Modal isShowing hide={hide} />, document.body) : null

Modal.propTypes = {
  hide: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired
}

export default Portal
